import { useFlagValue } from '@labrav/flags'
import { FeatureFlags } from './featureFlags'
import { Role } from './roles'
import { PlanType } from '@labrav/react-components'
import { CloudType } from '../modules/types'
import aws from '../images/aws.png'
import azure from '../images/azure.png'

export const backendUrl =
  import.meta.env.VITE_BACKEND_URL ??
  'https://0nikdg0hod.execute-api.us-west-2.amazonaws.com'

export const oktaDomain = import.meta.env.VITE_OKTA_DOMAIN
export const oktaClientID = import.meta.env.VITE_OKTA_CLIENT_ID

export const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN ?? ''
export const auth0ClientID = import.meta.env.VITE_AUTH0_CLIENT_ID ?? ''

export const oktaIssuer =
  import.meta.env.VITE_OKTA_ISSUER ?? `https://${oktaDomain}/oauth2/default`

export const segmentID = import.meta.env.VITE_SEGMENT_ID

export const engineServiceUrl =
  import.meta.env.VITE_ENGINE_SERVICE_URL ?? 'https://qa.api.labra.io/engine/v1'

export const labravAWSEngineServiceUrl =
  import.meta.env.VITE_AWS_ENGINE_SERVICE_URL ??
  'https://qa.api.labra.io/aws_engine/v1'

export const identityServiceUrl =
  import.meta.env.VITE_IDENTITY_SERVICE_URL ??
  'https://qa.api.labra.io/identity/v1'

export const labraAuth0APIAudience =
  import.meta.env.VITE_AUTH0_API_AUDIENCE ?? 'https://qa.api.labra.io'
export const engineServiceV2Url = engineServiceUrl.replace('v1', 'v2')

export const notifcationSeviceUrl =
  import.meta.env.VITE_NOTIFICATION_SERVICE_URL ??
  'https://qa.api.labra.io/notification/v1'

export const crmServiceUrl =
  import.meta.env.VITE_CRM_SERVICE_URL ?? 'https://qa.api.labra.io/crm/v1'

export const salesforceLoginURL = import.meta.env.VITE_SALESFORCE_LOGIN_URL
export const salesforceLoginStagingUrl = import.meta.env
  .VITE_SALESFORCE_STAGING_LOGIN_URL
export const salesforceClientID = import.meta.env.VITE_SALESFORCE_CLIENT_ID
export const salesforceRedirectURI =
  import.meta.env.VITE_SALESFORCE_REDIRECT_URI ??
  `${import.meta.env.VITE_BASE_URL}/settings/crm/callback`
export const slackRedirectURI =
  import.meta.env.VITE_SLACK_REDIRECT_URI ??
  'https://qa.app.labra.io/oppsync/settings/notifications/callback'
export const isEnabledFlag = async (key: FeatureFlags) => {
  const flagsClient = await useFlagValue(key).value

  return flagsClient
}

export const getSlackRedirectURI = () => {
  return import.meta.env.VITE_SLACK_REDIRECT_URI
}
export const getSalesforceRedirectURI = async () => {
  return import.meta.env.VITE_SALESFORCE_REDIRECT_URI
}
export const baseURL = import.meta.env.VITE_BASE_URL

export const slackClientID =
  import.meta.env.VITE_SLACK_CLIENT_ID ?? '1390004519943.1390007455959'

export const HubspotClientUrl =
  import.meta.env.VITE_HUBSPOT_LOGIN_URL ??
  'https://app-eu1.hubspot.com/oauth/authorize?client_id=b27c13fa-81c1-4b54-b1c4-66fb79e70eda&redirect_uri=http://localhost:3000/settings/crm/callback&scope=oauth%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.custom.read%20crm.objects.custom.read%20crm.objects.custom.write%20crm.objects.companies.write%20settings.users.read%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write'

export const slackLoginURL =
  import.meta.env.VITE_SLACK_LOGIN_URL ?? 'https://slack.com/oauth/v2/authorize'

export const cmcsExternalPageUrl =
  import.meta.env.VITE_CMCS_EXTERNAL_PAGE_URL ?? 'https://qa.cmcs.labra.io'

//The price id to be included in .env file
export const subscriptionPriceId = {
  yearly: {
    'STARTER PLAN': 'qwertyuiop',
    'GROWTH PLAN': 'asdfghjkl',
    'ENTERPRISE PLAN': 'zxcvbnm',
  },
  monthly: {
    'STARTER PLAN': 'qwerty',
    'GROWTH PLAN': 'ghjkl',
    'ENTERPRISE PLAN': 'vbnm',
  },
}

export const cloudTypes = [
  {
    name: 'AWS',
    disabled: false,
  },
  {
    name: 'AZURE',
    disabled: true,
  },
  {
    name: 'GCP',
    disabled: true,
  },

  {
    name: 'REDHAT',
    disabled: true,
  },
]

export const plans = {
  FLYOUT: [
    { label: 'Legacy', value: 'legacy' },
    { label: 'Free tier', value: 'free_tier' },
  ],
}

export const cloudNames = ['aws', 'azure', 'gcp', 'redhat']

export const mockServerEnabled = JSON.parse(
  import.meta.env.VITE_ENABLE_MOCK_SERVER ?? 'false'
)

export const defaultValueDebounceTime = 300
export const formDebounceTime = parseInt(
  import.meta.env.VITE_FORM_DEBOUNCE_TIME || defaultValueDebounceTime + ''
)
export const maxHeaderHeight = '31px'
export const rulesFormDebounceTime = 300
export const flyoutFormDebounceTime = 300
export const flyoutResaleSingleFieldFormDebounceTime = 40
export const CloudSettingsFieldDebounceTime = 200
export const copyMsgTimeout = 900
export const autosaveTimeout = 15000
export const defaultButtonColor = '#2814FF'
export const defaultButtonTextColor = '#FFFFFF'
export const defaultAlertColor = '#FFD8D8'
export const oppsyncDefaultColor = '#475672'
export const flyoutDefaultColor = '#518C84'
export const cloudfaasDefaultColor = '#77578C'
export const appUrl = import.meta.env.VITE_URL
export const newrelicAppId = import.meta.env.VITE_NEWRELIC_APP_ID
export const newrelicLicenseKey = import.meta.env.VITE_NEWRELIC_LICENCE_KEY
export const newrelicAPIKey = import.meta.env.VITE_NEWRELIC_API_KEY

export const cfLink =
  import.meta.env.VITE_CF_TEMPLATE_URL ??
  'https://eu-central-1.console.aws.amazon.com/cloudformation/home?region=eu-central-1#/stacks/quickcreate?templateURL=https://oppsync-crossaccount-role-template.s3.us-west-2.amazonaws.com/OppsyncIamRole.yml'

export type directionType = 'ace' | 'crm'
export type actionName = 'create' | 'update'
export type syncAttemptResultType =
  | 'success'
  | 'in_progress'
  | 'error'
  | 'warning'
export type syncAttemptErrorSource = 'internal' | directionType
export type environment = 'staging' | 'production'
export type userStatus = 'ACTIVE' | 'PENDING' | 'BLOCKED'
export type notificationMethod = 'email' | 'text'

export enum ObjectType {
  OPPORTUNITY = 'opportunity',
  LEAD = 'lead',
}

export type themeType = 'light' | 'dark'

export const rolesToCompare = [
  Role.SUPER_ADMIN,
  Role.PARTNER_OWNER,
  Role.ADMIN,
  Role.PARTNER_ADMIN,
  Role.PARTNER_STAFF,
]

export const enableNotificationUI = JSON.parse(
  import.meta.env.VITE_NOTIFICATION_UI_ENABLED ?? 'false'
)

export enum crmEnv {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const enableMissingMappedFieldsValidation = JSON.parse(
  import.meta.env.VITE_ENABLE_MISSING_VALIDATIONS ?? 'true'
)

export const internalRelation = 'internalRelationship'

export const showPossibleResolutions = JSON.parse(
  import.meta.env.VITE_SHOW_POSSIBLE_RESOLUTIONS ?? 'false'
)

export const enableTestRoute = JSON.parse(
  import.meta.env.VITE_ENABLE_TEST_ROUTE ?? 'false'
)

export const awsTemplateNameCMCM = 'marketplace_listing'

export const cloudAccountServiceUrl =
  import.meta.env.VITE_CLOUD_SERVICE_URL ??
  'https://qa.api.labra.io/cloud_account/v1'

export const ACCOUNT_ONBOARDING_ENDPOINT_MOCK = JSON.parse(
  import.meta.env.VITE_ACCOUNT_ONBOARDING_ENDPOINT_MOCK ?? 'false'
)
export const marketplaceServiceUrl =
  import.meta.env.VITE_MARKET_PLACE_SERVICE_URL ??
  'https://qa.api.labra.io/marketplace/v1'

export const overviewPageServiceUrl =
  import.meta.env.VITE_OVERVIEW_PAGE_SERVICE_URL ??
  'https://qa.api.labra.io/flyout_overview/v1'

export const MarketplaceSearchUrl =
  import.meta.env.VITE_MARKET_PLACE_SEARCH_SERVICE_URL ??
  'https://qa.api.labra.io/search/v1'

export const azureMarketplaceServiceUrl =
  import.meta.env.VITE_AZURE_MARKET_PLACE_SERVICE_URL ??
  'https://qa.api.labra.io/azure_marketplace/v1'

export const gcpMarketplaceServiceUrl =
  import.meta.env.VITE_GCP_MARKET_PLACE_SERVICE_URL ??
  'https://qa.api.labra.io/gcp_marketplace/v1'

export const flagsUrl =
  import.meta.env.VITE_FLAGS_URL ?? 'https://flags.labra.io'

export const flagsCacheTTLSeconds = (() =>
  parseInt(import.meta.env.VITE_FLAGS_TTL_SECONDS ?? '300'))()

export const serviceId = import.meta.env.VITE_SERVICE_ID ?? 'webapp'

export const serviceEnvironment = import.meta.env.VITE_ENVIRONMENT ?? 'qa'
export const viteSegmentApiWriteKey = import.meta.env.VITE_SEGMENT_API_WRITE_KEY

export const cloudProviderTypes = [
  {
    name: 'AWS',
    disabled: false,
  },
  {
    name: 'AZURE',
    disabled: true,
  },
  {
    name: 'GCP',
    disabled: true,
  },
  {
    name: 'REDHAT',
    disabled: true,
  },
]

export const defaultBuyerFormFields = [
  {
    title: 'first name',
    dataType: 'string',
    isRequired: true,
    validationType: null,
    minLength: 1,
    maxLength: 200,
  },
  {
    title: 'email',
    dataType: 'string',
    isRequired: true,
    validationType: 'email',
    minLength: 1,
    maxLength: 200,
  },
  {
    title: 'company name',
    dataType: 'string',
    isRequired: true,
    validationType: null,
    minLength: 1,
    maxLength: 200,
  },
]

export const oppSyncProductPrd = 'oppsync_prd'

export const rowsPerPageOptionsForTables = [10, 20, 30]

export const azureEngineServiceUrl =
  import.meta.env.VITE_AZURE_ENGINE_SERVICE_URL ??
  'https://qa.api.labra.io/azure_engine/v1'

export const graphDateFormatWithDay = '%d %b %y'
export const graphDateFormatWithoutDay = '%b %y'

export const azureScopesUrls = [
  'https://api.partner.microsoft.com/user_impersonation',
  'https://api.partnercenter.microsoft.com/user_impersonation',
]

export const searchDropDownOptions = {
  privateOfferoptions: ['Company', 'Offer ID'],
  subsciptionsOptions: [
    'Company',
    'AWS account ID',
    'Offer ID',
    'Subscription ID',
  ],
  resaleAuthorizationOptions: [
    'Authorization name',
    'Reseller name',
    'Authorization ID',
  ],
  revenueInsightsOptions: ['Company', 'Offer ID', 'Account ID'],
}

export const searchDropDownOptionMapping: {
  privateOffersOptionMapping: { [key: string]: string }
  subsciptionsOptionMapping: { [key: string]: string }
  resaleAuthorizationOptions: { [key: string]: string }
  revenueInsightsOptions: { [key: string]: string }
} = {
  privateOffersOptionMapping: {
    Company: 'company_name',
    'Offer ID': 'aws_offer_id',
  },
  subsciptionsOptionMapping: {
    Company: 'buyer_company_name',
    'AWS account ID': 'aws_account_id',
    'Offer ID': 'aws_offer_id',
    'Subscription ID': 'aws_agreement_id',
  },
  resaleAuthorizationOptions: {
    'Authorization name': 'agreement_name',
    'Reseller name': 'reseller_name',
    'Authorization ID': 'aws_agreement_id',
  },
  revenueInsightsOptions: {
    Company: 'buyer_company_name',
    'Offer ID': 'aws_offer_id',
    'Account ID': 'buyer_aws_account_id',
  },
}

export const flyoutS3Url = 'https://flyout-assets-s3.s3.amazonaws.com'
export const eulaStandardContractUrl =
  'https://assets.flyout.labra.io/standard_and_enterprise_eula/Standard-Contact-for-AWS-Marketplace-2022-07-14.pdf'
export const eulaStandardContractUrlGCP =
  'https://assets.flyout.labra.io/eula_files/Google+Cloud+Marketplace+Standard+Eula+V2.pdf'
export enum CRMTypes {
  salesforce = 'salesforce',
  hubspot = 'hubspot',
  connectwise = 'connectwise',
  labra = 'labra',
}

export const defaultCrmType = 'HubSpot'

export const standardResellerAgreementUrl =
  'https://assets.flyout.labra.io/standard_and_enterprise_eula/Reseller-Contract-for-AWS-Marketplace-2021-12-01.pdf'

export const offerExpireInDaysCount = 5
export const disableResaleAuthorizationDurationText =
  '(Only single use resale authorization are supported for flexible payment schedule option.)'
export const disableResaleFlexiblePayment =
  '(FPS is not supported for the subscription product type)'
export const addRecipientsToolTipMessage =
  "Recipients can't be updated when the offer status is expired, cancelled, cancellation in progress or failed."

export const updateOfferExpiringToolTipMessage =
  'You cannot update the offer expiration date when the offer status is in progress, cancelled, cancellation in progress or failed.'

export const planTypesForCommonFields = [
  PlanType.SAAS_CONTRACT,
  PlanType.PROFESSIONAL_SERVICES,
]

// eslint-disable-next-line no-useless-escape
export const JWTRegex =
  '^[A-Za-z0-9-_=]+\\.[A-Za-z0-9-_=]+\\.?[A-Za-z0-9-_.+/=]*$'

export const awsMarketplaceEligibleJuridiction =
  'https://docs.aws.amazon.com/marketplace/latest/userguide/user-guide-for-sellers.html#eligible-jurisdictions'

export const snsTopicARNPattern = /^arn:aws:sns:[\w-]+:\d{12}:[\w+=,_-]*$/

export const IamArnRegex = /^arn:aws:iam::\d{12}:role\/[\w+=,.@-]+$/

export const kmsKeyArnRegex =
  /^arn:aws:kms:[a-z0-9-]+:[0-9]{12}:key\/[\w+=,.@-]*$/

export const awsCasS3BucketNameRegex = /^arn:aws:s3:::[\w.-]*$/
export const urlPattern =
  /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
export const urlPatternWithHttps = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/

export const temporaryArn =
  'arn:aws:iam::123456789012:role/dummy-partnerId-for-managed'

export const flyoutCFTDescription =
  'In this section, you need to connect your cloud so that you can create and manage listings via Labra.'

export const flyOutCFTLink =
  import.meta.env.VITE_FLYOUT_CFT_URL ??
  'https://us-west-2.console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/create/review?templateURL=https://oppsync-assests-s3.s3.us-west-2.amazonaws.com/CFT/labra_flyout_latest.yaml&stackName=labra-flyout-role'

export const flyOutDataFeedsCFTLink = 'https://us-west-2.console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/create/review?templateURL=https://oppsync-assests-s3.s3.us-west-2.amazonaws.com/CFT/labraDataFeeds.yaml&stackName=labra-flyout-datafeeds-access'

export const awsCountryAllowedMessage =
  'Your company is from the AWS approved seller country list.'
export const awsCountryNotAllowedMessage =
  "Your Listing will be posted under Labra's account as your company is not from the AWS approved seller country list."
export const emptySpaceCharsAtStartAndEndRegex = /^(?!\s)(?![\s\S]*\s$)[\s\S]*$/
export const escapeSequenceRegex = /(\\t|\\b|\\n|\\r|\\f|\\'|\\"|\\\\)/
export const specCharRegEx = new RegExp(
  /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/ // eslint-disable-line
)
export const doubleQuoteRegex = /["]/
export const productPageWrapperSubHeading =
  'To build an effective listing in AWS Marketplace, it’s important that you communicate the value proposition of your product offering and provide buyers with all the information they need to make an informed decision about purchasing your product.'
export const productPageWrapperCloudSettingSubHeading =
  'To migrate your existing marketplace listings, choose all products from the table below which you want to migrate and start managing via Labra.'
export const fetchCountriesListS3Url =
  'https://flyout-assets-s3.s3.us-west-2.amazonaws.com/countries_list.json'
export const helpDrawerUrl = 'https://helpcenter.labra.io/hc/en-us'
export const productDimensionWrapperSubHeading =
  'To build an effective listing in AWS Marketplace, it’s important that you communicate the value proposition of your product offering and provide buyers with all the information they need to make an informed decision about purchasing your product.'
export const landingPageImageComponentHeading = 'What are we doing here?'
export const landingPageImageComponentSubHeading =
  'We will be recreating these landing pages as per your guidelines. Please provide the required information below.'
export const openAwsProductLinkOnBoarding =
  'https://aws.amazon.com/marketplace/pp/'
export const notificationCheckboxText =
  'Notifications will be sent exclusively to the specified email addresses. The selection of recipients for these emails is entirely at the users’ discretion. Labra assumes no responsibility for the aforementioned communication, and any resulting damages or liabilities will be the sole responsibility of the user in charge.'

// eslint-disable-next-line no-useless-escape
export const offerNameInvalidErrMsg =
  'Offer name cannot contain special characters like ("\\","<" or ">") or initial spaces.'

export const initialSpaceRegex = /^\S+[\S\s]*/
export const resaleAuthNameValidationRegex = /^[A-Za-z0-9]*$/
export const eulaSectionHeading = 'Service agreement'
export const eulaSubHeading = 'EULA version'
export const eulaTooltipLabel = 'Select EULA or add your own custom EULA files.'
export const TwoDecimalPointRegex = /^(?:\d*\.\d{1,2}|\d+)$/
export const ThreeDecimalPointRegex = /^(?:\d*\.\d{1,3}|\d+)$/
export const gcpBillingIdRegex = /\b(?:[A-Za-z0-9]{6}-){2}[A-Za-z0-9]{6}\b/
export const dateMonthYear = 'dd MMM, yyyy'

export const SaasProductSectionLinks = {
  awsAccountCreated:
    'https://docs.aws.amazon.com/marketplace/latest/userguide/user-guide-for-sellers.html#management-portal',
  sellerRegistrationCompleted: {
    sellerRegistrationHeader:
      'https://aws.amazon.com/marketplace/management/seller-settings/account/registered',
    sellerRegistrationSubHeader:
      'https://docs.aws.amazon.com/marketplace/latest/userguide/seller-registration-process.html',
  },
  freeTrialEnabled:
    'https://labraio.zendesk.com/knowledge/editor/01J957NT887FMCMPXHYK5H0AVY/en-us?brand_id=27469220067219',
  vendorInsights:
    'https://docs.aws.amazon.com/marketplace/latest/buyerguide/buyer-vendor-insights.html',
  firstPrivateOfferCreated:
    'https://helpcenter.labra.io//hc/en-us/articles/28274504851475-Private-Offers#h_01HVPPXA6HME2RPPWEFYZJGE03',
  apnPartnerRegistrationCompleted: {
    apnPartnerRegistrationCompletedHeader:
      'https://partnercentral.awspartner.com/partnercentral2/s/login',
    apnPartnerRegistrationCompletedSubHeader:
      'https://www.apn-portal.com/knowledgebase/articles/FAQ/How-Do-I-Register-and-Log-Into-AWS-Partner-Central?l=en_US&c=Search_Categories%3APartner_Central_Account&fs=Search&pn=1',
  },
  aceEligible: 'https://aws.amazon.com/partners/programs/ace/',
  poButtonAndGuidedDemoEnabled: '',
  ammpApnAccountsConnected:
    'https://docs.aws.amazon.com/partner-central/latest/getting-started/account-linking.html',
}

export const labraV2OnboardingReleaseDate =
  import.meta.env.VITE_NEW_LABRA_ONBOARDING_RELEASE_DATE ||
  '2024-01-02T05:57:14.303Z'
export const planFeaturesDescription =
  'When you create an offer with fixed pricing you can customise. list of features, or keep the same features as your regular pricing plan. The list appears on the purchase page for the offer.'
export const planFeatureCustomiseText =
  'To customise the features of this offer, use following table. To exclude a feature from appearing on the customer offer, select N/A.'
export const autoRenewDescription =
  'Enabling this will allow the customer to decide if this contract’s pricing and terms auto-renew. The customer can auto-renew the contract indefinitely unless an amendment is accepted'
export const alphaNumericErrorMsg =
  'Provide a valid string with only alphanumerics and underscore'
export const alphaNumericRegexErrorMsg =
  'Provide a valid string with only alphanumerics and underscore and cannot start with a number'
export const alphaNumericUnderscoreRegex = /^[a-zA-Z0-9_]+$/
export const alphaNumericRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/
export const bucketNameImage = 'flyout-assets'
export const bucketName = 'flyout-assets-s3'
export const oppsyncAssetBucket = 'oppsync-assets-s3'
export const standardLabraDateFormat = 'LLL d, yyyy'
export const sortableFields = ['lastUpdated', 'createdDate', 'targetCloseDate']
export const mandatoryStringForUsGovProduct = 'GovCloud'
export const awsRequirementsLink =
  'https://docs.aws.amazon.com/marketplace/latest/userguide/saas-guidelines.html'
export const awsCompanyDetailRequirementsLink =
  'https://docs.aws.amazon.com/marketplace/latest/userguide/saas-getting-started.html'
export const dataFeedProgramLink =
  'https://signin.aws.amazon.com/signin?redirect_uri=https%3A%2F%2Faws.amazon.com%2Fmarketplace%2Fmanagement%2Freports%2Fdata-feed-configuration%3Fstate%3DhashArgs%2523%26isauthcode%3Dtrue&client_id=arn%3Aaws%3Aiam%3A%3A015428540659%3Auser%2Faws-mp-seller-management-portal&forceMobileApp=0&code_challenge=Zp883_dL_XEfXGe4wpZ86GHa2dv39tjmYdp8DG6ET6I&code_challenge_method=SHA-256'
export const awsRequirementsText = 'AWS requirements'
export const awsAccountIdRegex = /^\d{12}$/
export const monthsDurationRegex = /^(?!0)([1-9]|[1-5][0-9]|60)$/
export const dyasDurationRegex =
  /^(?!0)([1-9][0-9]{0,2}|10[0-8][0-9]|109[0-5])$/

export const cloudLogos = {
  [CloudType.AWS]: aws,
  [CloudType.AZURE]: azure,
  [CloudType.GCP]: '',
  [CloudType.REDHAT]: '',
}
export const standardEulaUrl =
  'https://awsmp-offer-legal-documents-prod-iad.s3.us-east-1.amazonaws.com/prod/arn%3Aaws%3Acatalog%3Aus-east-1%3A%3Aoffer/offer-glxkv7m6pb3gk/482cd9a1-30c6-48a3-ab64-b66a3e7c9158?X-Amz-Security-Token=FwoGZXIvYXdzEDcaDL8pAiY%2FuP4gx1n1ayKIAj8AnIy8blggtym%2FSGytwJCKqsFhD2RCH9x2ehz2t1ji4kpJNUxlmL4V%2F%2FMu1TRas%2BycNDmO5wzEP6YXUP6neBNtidb3EgdhIal7XLUMIi%2Bmy7JVYTyXxtdZ42s3QhSeq0UEvKz5rs%2FE4Tuwm8m0llCmIz7P%2Fu%2BdYTGx%2FpwGqLtNL6Bm17%2FYL%2Fdygz3Vm3lQ7lUQI3rTKRKOfDdob9m%2BEUX0%2Be45I4bIM6xj0%2FXqnrCEi7Ohfs1DjMCh00dJ6KtD18p2Zvz81akU7qEUbfMrN3Yf9w8gRmDm8ZV3ppWVK8bhrsZH4DNd8osVyYFZSGX1mCLy0RjsXkfT%2FfrGIki2auY%2BubEkGnvzZSiquPSvBjJPeKTxzTwKn1pwR5rjXKLhWbGP7qzmAVJSE0hP4U5SSSZKkRgBx33MXsrT3Sy5C%2BWguFf4noZgmcWTwW0BLJqllcF0Zcepwo2xGuOwO0rcBg%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240322T055127Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Credential=ASIASPPEMYX2EICV5MFN%2F20240322%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=0ff04949ff57bb81ca77edf1a8863569c680fc143f2d8d839b59e3f63e119fe5'

export const productWithoutResale = [
  PlanType.PROFESSIONAL_SERVICES,
  PlanType.CONTAINER,
]
export const autoSaveThresold = 120
export const autoSaveThresoldTime =
  import.meta.env.VITE_AUTOSAVE_THRESOLD_TIME ?? autoSaveThresold
export const excludedProductTypes = [
  PlanType.SAAS_CONTRACT,
  PlanType.SAAS_SUBSCRIPTION,
]
export const allFieldsArrayNotifications = [
  'registrationCompleted',
  'privateOfferRenewalReminder',
  'subscribeFail',
  'entitlementRenew',
  'entitlementExpired',
  'entitlementUpgrade',
  'unsubscribePending',
  'unsubscribeSuccess',
  'privateOfferExpired',
  'privateOfferAccepted',
  'privateOfferCreated',
  'privateOfferExpiringSoon',
  'awsPrivateOfferRestrictionSuccess',
  'meteringSent',
  'awsProductListingLive',
  'productListingAwsTesting',
  'awsProductListingRejected',
  'freeTrialActivated',
  'freeTrialDeactivated',
  'awsResellerAgreementCreated',
  'awsResellerAgreementDeactivated',
  'awsResellerAgreementExpiringSeller',
  'awsResellerAgreementExpiredSeller',
  'awsPrivateOfferCreationFailed',
  'awsPrivateOfferCreationSuccess',
  'awsPrivateOfferRestrictionFailed',
  'freeTrialActivationInProgress',
  'freeTrialEnabled',
  'freeTrialDeactivationInProgress',
  'awsResellerAgreementCreationSuccess',
  'awsResellerAgreementCreationFailed',
  'awsResellerAgreementActiveSeller',
  'freeTrialDeactivationInProgress',
  'awsResellerAgreementDeactivatedSeller',
  'awsFdaStartedSeller',
  'registrationIncompleteSubscription',
  'registrationIncompleteContract',
  'registrationIncompleteOffer',
]
export const fetchAwsDimensionsCategoriesListUrl =
  'https://flyout-assets-s3.s3.us-west-2.amazonaws.com/dimensions_mappings.json'
export const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/
export const awsRegexAccountId = /^[0-9]+$/

export const awsContactUsURL = 'https://www.labra.io/company/contact-us/'
export const customDateRanges = [
  'Last 30 Days',
  'This Month',
  'Last Month',
  'Last 3 Months',
  'Last 6 Months',
  'This Year',
  'Last Year',
  'All Time',
]
export const excludedStatuses = ['OFFER_VIEWED', 'OFFER_EMAIL_SENT']

export const labraHelpCenter = 'https://helpcenter.labra.io/'
export const labraWebsiteLink = 'https://www.labra.io/'
export const labraSupportTicket = 'https://helpcenter.labra.io/requests/new'
export const labraSystemStatus = 'https://labra.statuspage.io/'
export const paymentStatusArray = ['disbursed', 'invoiced', 'defaulters']
export const allowedPlanTypesForUpdateExpiryDate = [
  PlanType.SAAS_SUBSCRIPTION,
  PlanType.SAAS_CONTRACT,
]
export const subscriptionEndDateValidationErrorMsg =
  'Subscription end date should be greater than Offer expiration date.'
export const expirationDateValidationErrorMsg =
  'Offer expiration date is required.'
export const keysWithCostToDecimnal = [
  'grossAmount',
  'grossRefund',
  'listingFee',
  'listingFeeRefund',
  'listingFeePercentage',
  'sellerTaxShare',
  'sellerTaxRefund',
  'awsTaxShare',
  'awsTaxShareRefund',
  'awsTaxShareListingFee',
  'awsTaxShareRefundListingFee',
  'wholeSaleCost',
  'wholeSaleCostRefund',
  'sellerNetRevenue',
]

export const labraSubmitSupportTicket =
  'https://helpcenter.labra.io/hc/en-us/requests/new'

export const overviewLineGraphDateRange = 'MMM yyyy'

export const revenueOverviewInfoText =
  'This is the billed revenue your products have generated from the subscriptions on both public & private offers.'

export const revenueByProductOverviewInfoText =
  'This is a descending order breakdown of total revenue brought in by each of your products. '

export const offersForBuyerOverviewInfoText =
  'This is the total number of ‘Accepted’ private offers  by your customers. '

export const offerForChannelOverviewInfoText =
  'This is the total number of offers created for your channel partners. It does not include any Resale Authorization in the ‘Draft’ or ‘Failed’ status.'

export const activeSubscriptionOverviewInfoText =
  'This is the total number of active subscriptions which you have currently. The bar graph below shows the month-wise aggregate of your active subscriptions for past 12 months.'

export const churnedSubscriptionOverviewInfoText =
  'This is the total number of your subscriptions which have churned in the past 12 months. It includes subscriptions which were canceled and those which have expired & were not renewed. The bar graph below shows the month-wise total churned subscriptions for past 12 months.'

export const accepetedOfferBySalesOverviewInfoText =
  'This bar graph shows the distribution of total number of ‘Accepted’ private offers which were created by each of your sales representative.'

export const offerAttentionOverviewInfoText =
  'This is the list of your private offers which are about to expire in the next 7 days and those which have already expired in past 7 days which require your immediate attention.'

export const upcomingRenewalsOverviewInfoText =
  'This is the list of all your subscriptions needing renewals since they are about to end/expire in the next 2 month and require your attention.'
export const requiredHeadersForSegmentData = [
  'Opportunity ID',
  'Segment',
  'Is Greenfield',
]
